<template>
  <div>
    <b-card class="mx-2 px-3">
      <h2 class="text-danger my-1 py1 border-bottom">
        <strong>AJUSTES DE LA APLICACIÓN</strong>
      </h2>
      <b-row
        v-for="(ajuste, index) in ajustes"
        :key="index"
      >
        <b-col
          v-if="ajuste.tipo === 'texto'"
          cols="12"
          class=" rounded p-1"
        >
          <div class="d-flex justify-content-between">
            <span>
              <strong>

                <label
                  :class="colorTexto"
                  for=""
                >{{ ajuste.nombre.toUpperCase() }}
                </label>
                <small
                  class="text-muted ml-1"
                  :class="colorTexto"
                >( {{ ajuste.descripcion }} )</small>

              </strong>
            </span>
            <div>
              <b-button
                v-if="!arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = true"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                class="btn-icon"
                size="sm"
                @click="actualizarAjuste(index, ajuste.id, ajustes[index].valor, ajuste.tipo)"
              >
                Guardar cambios <feather-icon icon="SaveIcon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = false"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </div>
          <b-form-input
            v-model="ajustes[index].valor"
            :disabled="!arrayEditable[ajuste.id]"
          />
        </b-col>

        <b-col
          v-if="ajuste.tipo === 'url'"
          cols="12"
          class=" rounded p-1"
        >
          <div class="d-flex justify-content-between">
            <span>
              <strong>

                <label
                  :class="colorTexto"
                  for=""
                >{{ ajuste.nombre.toUpperCase() }}
                </label>
                <small
                  class="text-muted ml-1"
                  :class="colorTexto"
                >( {{ ajuste.descripcion }} )</small>
              </strong>
            </span>
            <div>
              <b-button
                v-if="!arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = true"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                class="btn-icon"
                size="sm"
                @click="actualizarAjuste(index, ajuste.id, ajustes[index].valor, ajuste.tipo)"
              >
                Guardar cambios <feather-icon icon="SaveIcon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = false"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </div>
          <b-form-input
            v-model="ajustes[index].valor"
            :disabled="!arrayEditable[ajuste.id]"
          />
        </b-col>
        <b-col
          v-if="ajuste.tipo === 'numero'"
          cols="12"
          class=" rounded p-1"
        >
          <div class="d-flex justify-content-between">
            <span>
              <strong>

                <label
                  :class="colorTexto"
                  for=""
                >{{ ajuste.nombre.toUpperCase() }}
                </label>
                <small
                  class="text-muted ml-1"
                  :class="colorTexto"
                >( {{ ajuste.descripcion }} )</small>
              </strong>
            </span>
            <div>
              <b-button
                v-if="!arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = true"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                class="btn-icon"
                size="sm"
                @click="actualizarAjuste(index, ajuste.id, ajustes[index].valor, ajuste.tipo)"
              >
                Guardar cambios <feather-icon icon="SaveIcon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = false"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </div>
          <b-form-input
            v-model="ajustes[index].valor"
            type="number"
            :disabled="!arrayEditable[ajuste.id]"
          />
        </b-col>

        <b-col
          v-else-if="ajuste.tipo === 'imagen'"
          cols="12"
          class=" rounded p-1 border-secondary my-1"
        >
          <div class="d-flex justify-content-between">
            <span>
              <strong>

                <label
                  :class="colorTexto"
                  for=""
                >{{ ajuste.nombre.toUpperCase() }}
                </label>
                <small
                  class="text-muted ml-1"
                  :class="colorTexto"
                >( {{ ajuste.descripcion }} )</small>
              </strong>
            </span>
            <div>
              <b-button
                v-if="!arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = true"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                class="btn-icon"
                size="sm"
                @click="actualizarAjuste(index, ajuste.id, ajustes[index].valor, ajuste.tipo)"
              >
                Guardar cambios <feather-icon icon="SaveIcon" />
              </b-button>
              <b-button
                v-if="arrayEditable[ajuste.id]"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="() => arrayEditable[ajuste.id] = false"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </div>

          <b-img
            thumbnail
            center
            fluid
            :src="ajustes[index].valor ? ajustes[index].valor : 'SIN IMAGEN'"
            :alt="ajuste.nombre"
            width="120"
            style="margin-bottom:5px;"
          />
          <b-form-file
            v-model="arrayFile[ajuste.id]"
            :disabled="!arrayEditable[ajuste.id]"
            size="sm"
            accept=".jpg, .png, .jpeg, .gif, .svg"
          />

        </b-col>
      </b-row>
      <div class="d-flex justify-content-between mt-3 border-top pt-1">
        <b-button
          variant="outline-secondary"
          @click="reestablecerAjustes"
        >
          Reestablecer ajustes
        </b-button>

      </div>
    </b-card>

  </div>

</template>

<script>

import {
  BButton, BCol, BRow, BFormInput, BCard,
  BFormFile, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    BFormInput,
    BFormFile,
    BButton,
    BCol,
    BRow,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ajustes: [],
      arrayEditable: {},
      arrayFile: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    colorTexto() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return 'text-white'
      }
      return 'text-primary'
    },
  },
  created() {
    this.$http.get('/administrador/ajustes').then(res => {
      this.ajustes = res.data.data
      this.ajustes.forEach(value => {
        this.$set(this.arrayEditable, value.id, false)
        if (value.tipo === 'imagen') {
          this.$set(this.arrayFile, value.id, null)
        }
      })
    })
  },
  methods: {
    actualizarAjuste(index, id, valor, tipo) {
      const formData = new FormData()
      formData.append('_method', 'put')

      if (tipo === 'imagen') {
        formData.append('valor', this.arrayFile[id])
      } else {
        formData.append('valor', valor)
      }

      this.$http.post(`/administrador/ajustes/${id}`, formData).then(res => {
        this.ajustes[index] = res.data
        this.arrayEditable[id] = false
        this.actualizarStoreAjustes()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Cambio guardado!',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Error al guardar cambios!',
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
      })
    },

    reestablecerAjustes() {
      this.$swal({
        title: '¿Estas seguro reestablecer los ajustes?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, reestablecer!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.get('/administrador/ajustes/restablecer').then(res => {
            this.ajustes = res.data.data
            this.ajustes.forEach(value => {
              this.$set(this.arrayEditable, value.id, false)
              if (value.tipo === 'imagen') {
                this.$set(this.arrayFile, value.id, null)
              }
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: '¡Cambios de ajustes reestablecidos!',
                icon: 'CheckCircleIcon',
                variant: 'info',
              },
            })
            this.actualizarStoreAjustes()
          })
        }
      })
    },

    actualizarStoreAjustes() {
      this.$http.get('/administrador/ajustes').then(res => {
        store.state.ajustesApp.institucion = res.data.data.find(val => val.nombre === 'institucion').valor
        store.state.ajustesApp.titulo = res.data.data.find(val => val.nombre === 'título').valor
        store.state.ajustesApp.nombreAnio = res.data.data.find(val => val.nombre === 'nombre del año').valor
        store.state.ajustesApp.piePagina1 = res.data.data.find(val => val.nombre === 'pie pagina 1').valor
        store.state.ajustesApp.piePagina2 = res.data.data.find(val => val.nombre === 'pie pagina 2').valor
        store.state.ajustesApp.icono = res.data.data.find(val => val.nombre === 'icono').valor
        store.state.ajustesApp.logo = res.data.data.find(val => val.nombre === 'logo').valor
        store.state.ajustesApp.logoPequeno = res.data.data.find(val => val.nombre === 'logo pequeño').valor
        store.state.ajustesApp.logoTexto1 = res.data.data.find(val => val.nombre === 'logo texto 1').valor
        store.state.ajustesApp.logoTexto2 = res.data.data.find(val => val.nombre === 'logo texto 2').valor
        store.state.ajustesApp.imagenFondoLogin = res.data.data.find(val => val.nombre === 'imagen fondo login').valor
        store.state.ajustesApp.imagenFondoBody = res.data.data.find(val => val.nombre === 'imagen fondo body').valor
        store.state.ajustesApp.imagenDefecto = res.data.data.find(val => val.nombre === 'imagen por defecto').valor
        store.state.ajustesApp.manualUsuarioEstudiante = res.data.data.find(val => val.nombre === 'manual usuario estudiante').valor
        store.state.ajustesApp.manualUsuarioAdministrativo = res.data.data.find(val => val.nombre === 'manual usuario administrativo').valor
        store.state.ajustesApp.manualAdministrador = res.data.data.find(val => val.nombre === 'manual administrador').valor
        store.state.ajustesApp.vtUsuarioEstudiante = res.data.data.find(val => val.nombre === 'video tutorial usuario estudiante').valor
        store.state.ajustesApp.vtUsuarioAdministrativo = res.data.data.find(val => val.nombre === 'video tutorial usuario administrativo').valor
        store.state.ajustesApp.vtUsuarioAdministrador = res.data.data.find(val => val.nombre === 'video tutorial administrador').valor
        store.state.ajustesApp.pesoArchivo = res.data.data.find(val => val.nombre === 'Peso archivo (B)').valor
      })
    },
  },

}
</script>

<style lang="scss">
</style>
